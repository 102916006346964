<template>
  <div class="admin-new-user" :class="{ loading: loading }" :key="fmKey" v-bind="initData">
    <div class="admin-new-user-container">
      <div class="sec-font">Datos del usuario</div>
      <form id="register-data-form">
        <div class="block-input">
          <select name="documentType">
            <option v-for="(value, key) in $global.dictionary.documentType" :key="key" :value="key">{{ value }}</option>
          </select>
          <label>Tipo de documento</label>
        </div>
        <div class="block-input">
          <input type="text" name="documentId" required @keyup="validateInput" />
          <label>Numero de documento</label>
        </div>
        <div class="block-input">
          <input type="text" name="firstName" required @keyup="validateInput" />
          <label>Nombres</label>
        </div>
        <div class="block-input">
          <input type="text" name="lastname" required @keyup="validateInput" />
          <label>Apellidos</label>
        </div>
        <div class="block-input">
          <input type="email" name="userEmail" required @keyup="validateInput" />
          <label>Email</label>
        </div>
        <div class="block-input">
          <input type="text" name="phone" required @keyup="validateInput" />
          <label>Celular</label>
        </div>
        <div class="block-input">
          <input type="text" name="user" required @keyup="validateInput" />
          <label>Usuario</label>
        </div>
        <div class="block-input">
          <input type="password" name="userPass" required @keyup="validateInput" />
          <label>Contraseña</label>
        </div>
        <div class="block-input">
          <input type="password" name="userRePass" required @keyup="validateInput" />
          <label>Repetir contraseña</label>
        </div>
        <div class="block-input">
          <select name="userRole">
            <option value="coord">Coordinador</option>
            <option value="super">Super Administrador</option>
          </select>
          <label>Rol</label>
        </div>
        <div class="block-input">
          <select name="userBranch">
            <option value="medellin">Medellín</option>
            <option value="pereira">Pereira</option>
            <option value="cucuta">Cúcuta</option>
            <option value="bogota">Bogotá</option>
          </select>
          <label>Sede</label>
        </div>
        <div class="block-input">
          <input type="hidden" name="userId" v-if="user" :value="user._id" />
          <input type="nosubmit" value="Enviar" @click="validateData" readonly />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import global from "../../assets/js/global";
import Swal from "sweetalert2";
import api from "../../api";
import router from "../../router";
export default {
  data: () => {
    return {
      fmKey: 0,
      loading: null,
      user: null,
      formAction: "user/register",
    };
  },
  methods: {
    errorInput: function (el) {
      el.classList.add("data-error");
    },
    successInput: function (el) {
      el.classList.remove("data-error");
    },
    validateInput: function (el) {
      const _this = this;
      const _el = el.srcElement ? el.srcElement : typeof el === "object" ? el : el.srcElement;
      const _val = _el.value ? _el.value.trim() : "";
      if (_el.type === "text") {
        _val ? _this.successInput(_el) : _this.errorInput(_el);
      } else if (_el.type === "email") {
        global.validEmail(_val) ? _this.successInput(_el) : _this.errorInput(_el);
      } else if (_el.type === "password") {
        _val.length >= 4 ? _this.successInput(_el) : _this.errorInput(_el);
      }
    },
    validateData: function () {
      const _this = this;
      const fmInputs = document.querySelectorAll(".admin-new-user-container input");
      [].forEach.call(fmInputs, function (el) {
        if (el.hasAttribute("required")) {
          _this.validateInput(el);
        }
      });
      const dataErrors = document.querySelectorAll(".admin-new-user-container .data-error");
      if (dataErrors.length) {
        _this.validateDataError(dataErrors.length / 2);
      } else {
        _this.dataSubmitConfirm();
      }
    },
    validateDataError: function () {
      Swal.fire({
        text: "Error en los datos ingresados",
        icon: "error",
        confirmButtonText: "Revisar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
    dataSubmitConfirm: function () {
      const _this = this;
      Swal.fire({
        title: "Esta seguro ?",
        text: "Sus datos seran enviados",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#2196f3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si enviar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          _this.dataSend();
        }
      });
    },
    dataSend: async function () {
      const mbody = document.querySelector("body");
      const _this = this;
      const _host = `${process.env.VUE_APP_API_HOST}${this.formAction}`;
      const _data = new FormData(document.getElementById("register-data-form"));
      const _sending = _this.dataSending();
      const _send = await api
        .post(_host, _data)
        .then(function (response) {
          if (response.data.state) {
            _this.dataSendSuccess(response);
            if (response.data.isUpdate) {
              mbody.classList.remove("onmodal");
              router.go(-1);
            } else {
              _this.fmKey++;
            }
          } else {
            _this.dataSendError(response);
          }
        })
        .catch(function (response) {
          _this.dataSendError(response);
        });
    },
    dataSending: function () {
      Swal.fire({
        title: "Enviando",
        text: "Sus datos estan siendo enviados",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    dataSendError: function (response) {
      Swal.close();
      Swal.fire({
        title: "Error",
        html: response.data ? response.data.message : "Error del servidor",
        icon: "error",
        confirmButtonText: "Aceptar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
    dataSendSuccess: function (response) {
      Swal.close();
      Swal.fire({
        title: "Enviado",
        html: response.data ? response.data.message : "Los datos fueron enviados y procesados",
        icon: "success",
        confirmButtonText: "Aceptar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
    loadUserData: async function () {
      this.loading = true;
      const _host = `${process.env.VUE_APP_API_HOST}admin/users/edit?id=${this.$route.query.id}`;
      const response = await api.get(`${_host}`);
      this.user = response.data.user;
      if (this.user.documentType) {
        const _documentOld = {
          CC: "idcard",
          "Cédula extrangera": "idforeign",
          Pasaporte: "passport",
        };
        this.user.documentType = _documentOld[this.user.documentType] || this.user.documentType;
      }

      document.querySelector("[name=firstName]").value = this.user.name;
      document.querySelector("[name=lastname]").value = this.user.lastname;
      document.querySelector("[name=userEmail]").value = this.user.email;
      document.querySelector("[name=user]").value = this.user.user;
      document.querySelector("[name=userRole]").value = this.user.roles[0];
      document.querySelector("[name=userBranch]").value = this.user.branch;
      document.querySelector("[name=documentType]").value = this.user.documentType;
      document.querySelector("[name=documentId]").value = this.user.documentId;
      document.querySelector("[name=phone]").value = this.user.phone;

      let passwd = document.querySelector("[name=userPass]");
      let rpasswd = document.querySelector("[name=userRePass]");

      passwd.value = "******";
      rpasswd.value = "******";

      passwd.setAttribute("readonly", "");
      rpasswd.setAttribute("readonly", "");

      this.formAction = "admin/users/update";
      this.loading = false;
    },
  },
  computed: {
    initData: async function () {
      if (this.$route.query.id) {
        await this.loadUserData();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.admin-new-user {
  max-width: 320px;
  margin: auto;
  padding: $mpadding;
  &-container {
    padding: $mpadding;
    background: $alto;
    border-radius: $mradius;
    box-shadow: $dshadow;
  }
  .block-input {
    margin-top: 10px;
    position: relative;
    input,
    select {
      width: 100%;
      border-radius: 6px;
      appearance: none;
      border: none;
      height: 38px;
      text-align: left;
      font-size: 100%;
      padding-left: 7px;
      &[type="nosubmit"] {
        background: $primary_color;
        color: $white;
        cursor: pointer;
        text-align: center;
        padding-left: 0;
        appearance: none;
        &:active {
          opacity: 0.75;
        }
      }
      &.data-error {
        border: solid 1px $primary_color;
      }
    }
    input ~ label,
    select ~ label {
      position: absolute;
      pointer-events: none;
      transition: 0.3s ease-in-out;
      border-radius: 3px;
      top: 25%;
      left: 0;
      width: 100%;
    }
    input[type="text"]:not(empty),
    input[type="email"]:not(empty),
    input[type="password"]:not(empty),
    select {
      padding-top: 13px;
    }
    input:not(empty) ~ label,
    select:not(empty) ~ label {
      top: 0;
      left: 7px;
      font-size: 75%;
      width: auto;
      opacity: 0.8;
    }
    input[type="password"]:read-only {
      background: $pattens_blue;
    }
  }
}
</style>
