<template>
  <div class="window-modal-parent" v-bind="getAllData">
    <div class="absolute centered wrapper" v-if="visor && locale">
      <div class="window-modal">
        <div class="window-modal-header">
          <div class="window-modal-header-title">Editar usuario</div>
          <div class="window-modal-header-options">
            <a class="window-modal-header-options-button" @click="userView({ action: 'close' })">
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
        <div class="window-modal-content">
          <div class="window-modal-content-wrap">
            <p>Para editar un un Usuaio debes estar en MySpace Puedes ir dando click</p>
            <button class="window-modal-button"><a :href="$global.getApp('autologin').link.replace(/main/, 'users')">Aqui</a></button>
            <div class="window-modal-content-info" v-if="false">
              <AdminUsersNew />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/index.js";
import global from "../../assets/js/global";
import router from "../../router";
import AdminUsersNew from "../admin/AdminUsersNew";
export default {
  components: { AdminUsersNew },
  data: () => {
    return {
      visor: false,
      user: null,
      locale: null,
    };
  },
  methods: {
    userView: async function (params) {
      const mbody = document.querySelector("body");
      if (params.action == "open") {
        mbody.classList.add("onmodal");
        this.visor = true;
      } else if (params.action == "close") {
        this.visor = null;
        mbody.classList.remove("onmodal");
        router.go(-1);
      }
    },
    dateFormat: (date) => {
      return global.dateLocaleFormat(date);
    },
    getdataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
  },
  computed: {
    getAllData: async function () {
      await this.getdataLang();
      this.userView({ action: "open" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.window-modal-parent {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 999;
  .wrapper {
    padding: $mpadding;
  }
  .window-modal {
    position: relative;
    background-color: $white;
    max-width: 350px;
    margin: auto;
    border-radius: $mradius;
    max-height: calc(100vh - #{$mpadding});
    overflow: hidden;
    overflow-y: auto;
    &-button {
      margin-top: 5px;
      width: 50px;
      height: 25px;
      border: none;
      border-radius: 5px;
      background-color: $primary-color;
      color: white;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      background-color: $white;
      border-bottom: solid 1px $alto;
      position: sticky;
      top: 0;
      &-title {
        padding: $mpadding;
      }
      &-options {
        &-button {
          display: inline-block;
          padding: $mpadding;
          cursor: pointer;
        }
      }
    }
    &-content {
      &-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-info {
        width: 100%;
      }
    }
  }
}
</style>
